@bg-grey-color: rgba(5, 5, 5, 0.2);
@bg-green-color: #19a04b;

.custom {
  height: 100%;

  &__steps {
    max-width: 65%;
    margin: 0 auto;
    padding: 24px 0;
  }

  .ant-steps-item {
    .ant-steps-item-tail {
      padding: 8px 24px;
      margin-inline-start: 64px;

      &:after {
        height: 8px;
        background-color: @bg-grey-color !important;
      }
    }

    .ant-steps-item-container .ant-steps-item-icon {
      width: 48px;
      height: 48px;
      display: inline-block;
      background-color: @bg-grey-color;
    }

    svg {
      margin-top: 10px;
    }

    &:nth-child(3) {
      .ant-steps-item-container {
        .ant-steps-item-content {
          .ant-steps-item-title {
            width: 113px;
            margin-left: 9px;
          }
        }
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-tail::after {
      background-color: @bg-green-color !important;
    }

    .ant-steps-item-title {
      color: #696f88 !important;
    }

    .ant-steps-item-container .ant-steps-item-icon {
      background-color: #5ebb7f;
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-title {
      color: #14803c !important;
      font-weight: 600;
    }

    .ant-steps-item-container .ant-steps-item-icon {
      background-color: @bg-green-color;

      path {
        fill: white;
      }
    }
  }
}

.container {
  background-color: #ffff;
  border-radius: 8px;
}

.container .title_section__subTitle {
  line-height: 3;
}

.complete-section-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.country-tooltip {
  .context {
    font-size: 12px;

    p {
      margin-bottom: 5px;
      font-weight: 600;
    }
  }

  margin: 0 0 0 -15px !important;
}

.container .ant-collapse-expand-icon {
  padding-inline-end: 0px !important
}
@ant-prefix: syngenta-ant;