@bg-grey-color: #f3f4f6;

.error-boundary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.error-boundary-label {
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 46px;
  color: rgb(46, 51, 125);
}

.common-model {
  .ant-modal-header {
    margin-bottom: 0;
  }

  &__submit-btn {
    margin-right: 10px;
  }
}

.title_section {
  &__title {
    display: flex;
    align-items: baseline;
  }

  &__subTitle {
    color: #696f88;
    font-size: 14px;
    line-height: 20px;
    margin: 0 5px 4px 0;
  }

  .ant-modal-body {
    padding: 20px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.assesment-footer {
  float: right;
  margin-top: 40px;

  & button {
    margin-left: 5px;
  }
}

.tab-border(@paddingTop: 3px) {
  background-color: #ededf2 !important;
  border-radius: 40px !important;
  padding: @paddingTop 8px;
}

.syngenta-ant-tabs {
  margin-top: 16px;

  &-nav:before {
    border-bottom: 0 !important;
  }

  &-nav-list {
    .tab-border(6px);
  }

  &-tab {
    .tab-border;

    &-active {
      background-color: #14803c !important;

      div {
        color: #f3f4f6 !important;
        font-weight: 500;
      }
    }
  }
}

.custom-multiselect {
  .selected-items {
    margin-bottom: 20px;

    .ant-tag {
      font-size: 14px;
      padding: 5px 8px;
      margin: 10px 10px 0 0;

      &-close-icon {
        font-size: 16px;
      }
    }
  }

  .defaultButton {
    padding: 4px 16px !important;
  }
}

.custom-collapse {
  background-color: white;
  border: none !important;

  & .ant-collapse-item {
    border: 1px solid #c2c7d0 !important;
    border-radius: 8px !important;
    margin-bottom: 20px;

    & .ant-collapse-content {
      border-top: none !important;
    }
  }

  .ant-collapse-header {
    align-items: baseline !important;
  }

  .panel-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .editSection-btn-wrapper {
      background: transparent;
      border: none;

      .editSection-btn {
        padding: 0;
        display: flex;
        align-items: center;
        color: #0092e4;
        font-size: 14px;
        cursor: pointer;
        margin-right: 40px;
      }
    }
  }
}

.custom-suffix {
  color: #696f88;
}

.custom-forms.center .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}

.multi-option {
  height: 32px;

  .checkbox {
    margin-right: 10px;
  }

  input[type="checkbox"] {
    width: 18px !important;
    height: 18px !important;
  }
}

.custom-card {
  background-color: #f5f5f5;
  border: 0;
  white-space: pre-line;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  pointer-events: auto;
}

.alert-styles {
  padding: 12px 16px;
  margin-bottom: 12px;
  background-color: "#FFF3DD";
  border-color: "F0C355";
  line-height: 18px;
}

.alert {
  .alert-styles {
    margin-bottom: 16px;
  }

  .ant-alert-warning {
    background-color: #FFF3DD;
  }
}

.custom-disabled {
  color: unset !important;
  background-color: unset !important;
  border-color: white !important;
  cursor: auto !important;
}

.editForm {
  margin-bottom: 24px;

  &__content {
    display: flex;
    align-items: center;
    font-size: 16px;

    &__edit {
      margin-right: 4px;
      color: #a3a9b9;
    }
  }
}

.form-add-new-btn {
  color: #0092e4 !important;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.edit-section {
  .ant-select-item-option-content {
    white-space: break-spaces !important;
    word-break: break-word !important;
  }

  border-bottom: 1px solid rgb(218, 217, 217);
}

.edit-section:last-child {
  border-bottom: none;
}

.dynamicform-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 35vh;
  padding-right: 3px !important;

  &::-webkit-scrollbar {
    width: 7px;
  }
}

.fertilizerApplications-wrapper {
  max-height: 65vh;
}

#fertilizerApplications {
  .footer-section {
    border-top: 1px solid #cfcccc;
    margin: 0 -20px;
    padding: 16px 20px 0 0;
  }
}

.fertilizerApplications_border {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #c2c7d0;
  margin-bottom: 16px;

  .custom-npk {
    background-color: #f6f2fe;
  }

  .npk-top-border {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-left: 16px !important;

    &>div {
      margin: 0;
    }
  }

  .npk-left-border {
    border-bottom-left-radius: 8px;
    padding-left: 16px !important;
  }

  .npk-right-border {
    border-bottom-right-radius: 8px;
    padding-right: 16px !important;
  }

  .npk-bottom-border {
    margin-bottom: 16px;
  }

  .emmission {
    .ant-form-item {
      margin-bottom: 0;

      .ant-form-item-row {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: baseline;

        .ant-form-item-control {
          width: 25px;
          flex-grow: unset;
        }
      }
    }
  }
}

.popover-overlay {
  cursor: pointer;

  .ant-popover-content {
    width: 460px;

    .ant-popover-inner {
      padding: 0;

      .search-box {
        margin: 10px;
        width: calc(100% - 20px);
        background-color: @bg-grey-color;
        border: 1px solid #c2c7d0;

        input {
          background-color: @bg-grey-color;
        }
      }

      .select-org-btn {
        border: none;
        cursor: pointer;
        width: 100%;
        position: relative;
        text-align: start;
        max-height: 190px;
        min-height: 80px;
        overflow-y: auto;
        background-color: #ffffff;
        list-style-type: none;
        padding: 0;

        .active {
          background-color: #f3f4f6;
        }

        li {
          padding: 8px 12px;
          font-weight: 400;

          &:hover,
          &:active {
            background-color: #f3f4f6;
          }
        }

        .empty-message {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          padding: 16px;
          text-align: center;
        }
      }

      padding-bottom: 1px;
    }
  }

  .ant-popover-arrow {
    display: none;
  }
}

.imported-data-tag {
  font-weight: bold;
  position: absolute;
  top: -12px;
  left: 16px;
  z-index: 1;
  padding: 0px 8px;
  font-size: 13px;
  line-height: 20px;
  background: #C6E6FF;
  letter-spacing: 1px;
  border-radius: 4px
}

.ag-ops-single-select {
  .ant-select-selector {
    background: #EAF6FF !important;
  }
}

.default-single-select {
  .ant-select-selector {
    background: white;
  }
}

@ant-prefix: syngenta-ant;