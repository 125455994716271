.logoutOverlayBlock {
  width: 320px;
  min-height: 120px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-top: 10px;
  right: 10px;
  position: absolute;
  top: 38px;
  z-index: 1000;
}

.profileInfo {
  padding: 15px 15px;
  border-bottom: 1px solid #dfe2e7;
}

.profileInfo h3 {
  font-weight: 600;
  color: #14151c;
}

.profileUserData {
  margin-top: 10px;
  margin-bottom: 10px;
}

.profileUser {
  width: 24px;
  height: 24px;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  text-align: center;
  color: #4d5165;
  border: 1px solid #e8eaed;
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px 3px;
  font-weight: 600;
  background: #f2f4f6;
  float: left;
}

.profileUserData span {
  color: #14151c;
}

.logoutLink {
  background: white;
  border: none;
  padding: 12px 15px 15px 15px;
}

.logoutLink span {
  color: #cf3537;
  cursor: pointer;
}

.logoutLink span:hover {
  color: #ee3f42;
}

.profileInfo .syngenta-ant-radio-button-wrapper {
  cursor: pointer;
}

.syt-antd-dropdown-hidden {
  display: none;
}

.CropwiseLogo {
  & .expand-icon {
    width: 210px;
    height: 50px;
    margin: 15px;
  }

  & .collapse-icon {
    width: 40px;
    height: 40px;
    margin: 15px;
  }
}

.syt-antd-layout-header {
  height: 60px;
}

.navbarLogo {
  line-height: 0;
  margin: 0;
  cursor: pointer;

  .organization {
    margin-top: 10px;

    .ant-typography {
      margin: 10px 5px 0px 0px !important;
    }
  }

  .ant-spin {
    margin-top: 14px;
  }
}

.navbar-header-menus {
  max-width: 75%;
  width: 25% !important;
}

.navbar-header {
  padding-bottom: 12px
}

.ant-dropdown-link {
  background: white;
  border: none;
}

.logo {
  margin-right: 145px;
  border: none;
  width: 190px;
  height: 50px;
  cursor: pointer;
}
@ant-prefix: syngenta-ant;